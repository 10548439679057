import React from "react"

// Components
import AppPanel from "@views/AppPanel"
import Headline from "@components/Headline"

export default function Custom404() {
    return (
        <AppPanel>
            <Headline>404 - That page does not seem to exist...</Headline>
            <center>
                <iframe
                    src="https://giphy.com/embed/l2JehQ2GitHGdVG9y"
                    width="480"
                    height="362"
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </center>
        </AppPanel>
    )
}
