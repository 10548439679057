import React from "react"
import PropTypes from "prop-types"

// Components
import Typography from "@components/Typography"

// Material UI
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"

const styles = (theme) => ({
    container: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
})

const Headline = React.forwardRef(function Headline(props, ref) {
    const {
        children,
        variant = "h4",
        component = "h1",
        gutterBottom = true,
        marked = "none",
        classes,
        ...others
    } = props

    return (
        <Container className={classes.container}>
            <Typography
                ref={ref}
                variant={variant}
                component={component}
                gutterBottom={gutterBottom}
                marked={marked}
                align="center"
                noWrap
                {...others}
            >
                {children}
            </Typography>
        </Container>
    )
})

Headline.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Headline)
